<template>
  <div class="edrea_tm_section" id="news">
    <div class="section_inner">
      <div class="edrea_tm_news swiper-section">
        <div class="edrea_tm_main_title">
          <h3>Education | <span class="coloring">Work History</span></h3>
        </div>
        <div class="news_list">
          <swiper
            :loop="false"
            :slidesPerView="1"
            :spaceBetween="0"
            :loopAdditionalSlides="1"
            :autoplay="{
              delay: 6000,
            }"
            :navigation="{
              nextEl: '.my_next',
              prevEl: '.my_prev',
            }"
            :pagination="pagination"
            :breakpoints="{
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @slideChange="onSlideChange"
            :modules="modules"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-slide"
              v-for="(data, i) in newsData"
              :key="i"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-3.jpg" alt="" />
                  <div class="main" :data-img-url="data.img"></div>
                  <a class="edrea_tm_full_link news_popup" href="#"></a>
                </div>
                <div class="details">
                  <h3>
                    <a href="#">{{ data.title }}</a>
                  </h3>
                  <span
                    ><a href="#">{{ data.tag }}</a></span
                  >
                </div>

                <!-- News Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="news_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div class="main" :data-img-url="data.img"></div>
                    </div>
                    <div class="news_main_title">
                      <h3>{{ data.title }}</h3>
                      <span
                        ><a href="#">{{ data.tag }}</a></span
                      >
                      <div></div>
                    </div>
                    <div class="text">
                      <p v-for="(text, t) in data.des" :key="t">
                        {{ text }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- /News Popup Informations -->
              </div>
            </swiper-slide>
            <div class="edrea_tm_swiper_progress fill">
              <div class="my_pagination_in">
                <span class="currentNews">0</span>
                <span class="pagination_progress"
                  ><span class="all allNews"><span></span></span
                ></span>
                <span class="totalNews">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"
                      ><i class="icon-left-open-1"></i
                    ></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"
                      ><i class="icon-right-open-1"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";

export default {
  name: `NewsComponent`,
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".edrea_tm_swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(
            current,
            total,
            "currentNews",
            "totalNews",
            "allNews"
          );
        },
      },
    };
  },
  data() {
    return {
      newsData: [
        {
          title: "UTA",
          img: "img/resume/1.jpg",
          tag: "Education, BFA",
          des: [
            "BFA in Visual Communications (Graphic Design)",
          ],
        },
       
        {
          title: "NCH Corporation",
          img: "img/resume/2.jpg",
          tag: "Interactive Graphic Designer",
          des: [
            "Apps, Social Media, Web, Video Production Animation, and Print. Working with a variety of platforms, such as, Oxcyon, Sharepoint, SalesForce, Wordpress, SEO, AEM.",
          ],
        },
        {
          title: "Ride For The Brand",
          img: "img/resume/3.jpg",
          tag: "Web Developer",
          des: [
            "Web Developer, Full-time ad agency web-developer. Built websites: Fully Responsive, Static, Custom Wordpress Sites Email Blasts, etc... I worked strictly as a developer.",
          ],
        },
        {
          title: "AMS Productions",
          img: "img/resume/4.jpg",
          tag: "Internship",
          des: [
            "Assisted in Art Department; Web and Print Advertising, Storyboards Research, Production Assited on Film Shoots, Copywriting, and Image Editing."
          ],
        },
      ],
    };
  },
};
</script>
