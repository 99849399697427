<template>
  <div class="edrea_tm_section" id="portfolio">
    <div class="section_inner">
      <div class="edrea_tm_portfolio swiper-section">
        <div class="edrea_tm_main_title">
          <h3>Creative <span class="coloring">Portfolio</span></h3>
        </div>
        <div class="portfolio_list gallery_zoom">
          <swiper
            :loop="false"
            :slidesPerView="1"
            :spaceBetween="0"
            :loopAdditionalSlides="1"
            :autoplay="{
              delay: 6000,
            }"
            :navigation="{
              nextEl: '.my_next',
              prevEl: '.my_prev',
            }"
            :pagination="pagination"
            :breakpoints="{
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @slideChange="onSlideChange"
            :modules="modules"
            class="swiper-container"
          >
          
        
            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/1.webp"></div>
                </div>
                <div class="details">
                  <h3>Websites</h3>
                  <span>Responsive / Wordpress / TailwindCSS</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/portfolio/4.jpg" alt="" />
                      <div
                        class="main"
                        data-img-url="img/portfolio/4.jpg"
                        style="background-image: url('img/portfolio/1.webp')"
                      ></div>
                    </div>
                    <div class="details">
                      <h3>Web</h3>
                      <span>Modern Web Development</span>
                    </div>
                    <div class="text">
                      <p>
                       As a web developer in the modern digital landscape, I am committed to creating websites that are not only visually appealing but also highly functional across various devices and platforms. To achieve this, I prioritize the implementation of responsive web design principles. This approach ensures that the websites I build deliver an optimal user experience by seamlessly adapting their layout and content to fit different screen sizes and resolutions, from desktop monitors to mobile phones and tablets. Understanding that the majority of internet browsing is now performed on mobile devices, responsiveness is no longer a luxury—it's a necessity.     </p>

  <p>In addition to responsive design, I harness the power of utility-first CSS frameworks like TailwindCSS to accelerate the development process while maintaining the flexibility to customize designs without constraints. TailwindCSS provides a vast set of utility classes that can be composed directly in the HTML, giving me the ability to rapidly prototype and iterate on designs without the overhead of writing custom CSS for each component. This results in cleaner, more maintainable code and allows for significant improvements in terms of both performance and development speed.     </p>

  <p>Moreover, I combine the strengths of responsive design and TailwindCSS with robust Content Management Systems (CMS) such as WordPress. By doing so, I enable clients to manage their content efficiently without compromising on design and functionality. WordPress's extensibility through themes and plugins allows me to tailor each website to the specific needs of the client while ensuring that content updates are easy and non-technical users can handle them—thereby streamlining the content management process. With WordPress powering over 40% of the web, it has proven to be a reliable and user-friendly platform that can be leveraged to build anything from small business websites to large-scale enterprise solutions. Through the use of modern web development practices, I aim to deliver high-quality, accessible, and scalable websites that stand the test of time and deliver a competitive edge in the ever-evolving digital marketplace.     </p>
                 
                    
                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/portfolio/website-c.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/website-c.jpg"
                              ></div>
                              
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/8.jpg"
                              ></div>
                           
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/9.jpg"
                              ></div>
                           
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>

                        <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/3.webp"></div>
                </div>
                <div class="details">
                  <h3>Illustration</h3>
                  <span>Photoshop / Illustrator</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div
                        class="main"
                        data-img-url="img/portfolio/portrait-d.jpg"
                        style="background-image: url('img/portfolio/portrait-d.jpg')"
                      ></div>
                    </div>
                    <div class="details">
                      <h3>Illustration</h3>
                      <span>Digital Art</span>
                    </div>
                    <div class="text">
                      <p>
                       In my design process, I strategically leverage the strengths of both Adobe Illustrator and Adobe Photoshop to achieve impeccable results that fine-tune my craft. Starting with Illustrator, I utilize its powerful vector-based tools to create clean, scalable graphics and intricate illustrations that require precise lines and shapes. This allows for endless scalability in design without loss of resolution, which is ideal for creating logos, icons, and other elements that need to maintain visual integrity at various sizes. Once the vector elements are crafted to perfection in Illustrator, I transition to Photoshop to add depth and texture, which brings the designs to life. Photoshop's pixel-based environment is perfect for editing images, creating complex composites, and applying detailed adjustments and effects that enhance the overall composition. This seamless workflow between Illustrator and Photoshop enables me to harness the full potential of each software, resulting in a polished, professional aesthetic that stands out in the digital and print landscapes.
                      </p>
                    
                    </div>
                    <div class="additional_images">
                      <ul class="gallery_zoom">
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/portrait-a.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/portrait-a.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/portrait-b.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/portrait-b.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="list_inner">
                            <div class="image">
                              <img src="img/thumbs/4-2.jpg" alt="" />
                              <div
                                class="main"
                                data-img-url="img/portfolio/portrait-c.jpg"
                              ></div>
                              <a
                                class="edrea_tm_full_link zoom"
                                href="img/portfolio/portrait-c.jpg"
                              ></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>
    <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/2.webp"></div>
                </div>
                <div class="details">
                  <h3>Animation Reel</h3>
                  <span>video</span>
                </div>
                <a
                  class="edrea_tm_full_link popup-youtube"
                  href="https://www.youtube.com/embed/R4qFdaWaX8Y?autoplay=1"
                ></a>
              </div>
            </swiper-slide>
      

                        <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/4.webp"></div>
                </div>
                <div class="details">
                  <h3>Apps</h3>
                  <span>UX UI App design and development</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/portfolio/4.webp" alt="" />
                      <div
                        class="main"
                        data-img-url="img/portfolio/4.webp"
                        style="background-image: url('img/portfolio/4.webp')"
                      ></div>
                    </div>
                    <div class="details">
                      <h3>Apps</h3>
                      <span>UX / UI Development</span>
                    </div>
                    <div class="text">
                      <p>
                        Building applications with Adobe Experience Manager (AEM) in a Scrum environment harnesses the robust capabilities of AEM for digital experience management while capitalizing on the agility and collaboration fostered by the Scrum framework. The Scrum methodology, with its emphasis on flexibility, iterative development, and regular feedback cycles, complements AEM's powerful tools for content management, allowing teams to efficiently prototype, develop, and deploy multifaceted apps.
                      </p>
                      <p>
                        Leveraging AEM within this agile context empowers developers and content creators alike, enabling rapid adjustments to content and design that respond effectively to user feedback or changing business requirements, thereby delivering a more engaging and effective application experience.
                      </p>
                    </div>
                 
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide>

            <swiper-slide class="swiper-slide">
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/portfolio/7.webp"></div>
                </div>
                <div class="details">
                  <h3>Logos</h3>
                  <span>Logo Design</span>
                </div>
                <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

                <!-- Portfolio Popup Informations -->
                <div class="edrea_tm_hidden_content">
                  <div class="portfolio_popup_details">
                    <div class="top_image">
                      <img src="img/portfolio/7.webp" alt="" />
                      <div
                        class="main"
                        data-img-url="img/portfolio/7.webp"
                        style="background-image: url('img/portfolio/7.webp')"
                      ></div>
                    </div>
                    <div class="details">
                      <h3>Logos</h3>
                      <span>Logo Design</span>
                    </div>
                    <div class="text">
                      <p>
                        As a specialist in logo design, I utilize Adobe Illustrator exclusively to craft bespoke brand identities that resonate with the intended audience and stand the test of time. Illustrator is the industry-standard vector graphic software, and it is indispensable for creating scalable, crisp, and versatile logos that retain their quality across various mediums; whether displayed on a smartphone screen or across the facade of a building.
                      </p>
                      <p>
                        My logo design process in Illustrator starts with understanding the brand's ethos, target market, and the message the logo needs to convey. I invest time in research and sketching preliminary concepts, which serve as the blueprint for the digital design. Transitioning from paper to pixels, I bring these sketches into Illustrator, where I begin the meticulous process of building the logo piece by piece.
                      </p>
                    </div>
                 
                  </div>
                </div>
                <!-- /Portfolio Popup Informations -->
              </div>
            </swiper-slide> 
       
            <div class="edrea_tm_swiper_progress fill">
              <div class="my_pagination_in">
                <span class="current">0</span>
                <span class="pagination_progress"
                  ><span class="all"
                    ><span
                      style="
                        transform: translate3d(0px, 0px, 0px) scaleX(0.75)
                          scaleY(1);
                      "
                    ></span></span
                ></span>
                <span class="total">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"
                      ><i class="icon-left-open-1"></i
                    ></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"
                      ><i class="icon-right-open-1"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper core and required modules

// Import Swiper Vue.js components
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";

export default {
  name: `PortfolioComponent`,
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".edrea_tm_swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(current, total);
        },
      },
    };
  },
};
</script>
